<template>
  <div>
    <van-divider>基本信息</van-divider>
    <van-form @submit="handleSubmit">
      <van-field label="联系人" name="contactName" v-model="formData.contactName" placeholder="联系人" :rules="rules"></van-field>
      <van-field label="电话" name="phone" v-model="formData.phone" placeholder="请输入电话" :rules="rules"></van-field>
      <van-field label="公司名称" name="companyName" v-model="formData.companyName" placeholder="请输入公司名称" :rules="rules"></van-field>
      <van-field label="省/直辖市" name="provinceId" v-model="formData.provinceName" is-link :rules="rules" readonly @click="popupVisible=true"></van-field>
      <van-field label="市" name="cityId" v-model="formData.cityName" is-link :rules="rules" readonly @click="popupVisible=true"></van-field>
      <van-field label="地址" name="address" v-model="formData.address" :rules="rules" placeholder="请输入公司名称"></van-field>
      <el-row type="flex" justify="space-around" style="margin-top:20px">
        <el-col :span="11">
          <van-button type="info" block native-type="submit">提交</van-button>
        </el-col>
        <el-col :span="11">
          <van-button type="primary" block to="WapMyAgent">我的登记</van-button>
        </el-col>
      </el-row>
    </van-form>
    <van-popup v-model="popupVisible" position="bottom">
      <van-picker :columns="columnsArea" show-toolbar @cancel="popupVisible=false" @confirm="handleConfirm"></van-picker>
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
Vue.use(Vant);
export default {
  data() {
    return {
      popupVisible: false,
      formData: {
        contactName: "",
        phone: "",
        companyName: "",
        provinceId: 0,
        cityId: 0,
        provinceName: "",
        cityName: "",
        address: "",
      },
      rules: [{ required: true, message: "必填", trigger: "onChange" }],
      columnsArea: [],
    };
  },
  methods: {
    handleSubmit() {
      let that = this;
      that.axios.post("Base_Agent/AddModel", that.formData).then(() => {
        that.$router.push("WapMyAgent");
      });
    },
    getProvince() {
      let that = this;
      that.axios
        .post("Area/GetProvinceForVantPicker")
        .then(function (response) {
          that.columnsArea = response.data.data;
        });
    },
    handleConfirm(v, a) {
      let that = this;
      that.popupVisible = false;
      that.formData.provinceName = v[0];
      that.formData.cityName = v[1];
      that.formData.provinceId = that.columnsArea[a[0]].id;
      that.formData.cityId = that.columnsArea[a[0]].children[a[1]].id;
    },
  },
  mounted() {
    let that = this;
    document.title = "经销商登记";
    that.getProvince();
    that.axios
      .post("OfficialAccount/GetWXScanAPI", { onlyText: window.location.href })
      .then(function (response) {
        that.wx = require("weixin-js-sdk");
        that.wx.config({
          debug: false,
          appId: response.data.data.appId,
          timestamp: response.data.data.timestamp,
          nonceStr: response.data.data.nonceStr,
          signature: response.data.data.signature,
          jsApiList: ["getLocation"],
        });
        that.wx.ready(function () {
          that.wx.getLocation({
            type: "wgs84",
            success: function (response) {
              that.formData.latitude = parseFloat(response.latitude);
              that.formData.longitude = parseFloat(response.longitude);
            },
          });
        });
        that.wx.error(function (err) {
          alert("error" + JSON.stringify(err));
        });
      });
  },
};
</script>

<style>
</style>